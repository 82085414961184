   import axios from "axios";
const API = axios.create({
  baseURL: window.location.hostname.includes("integratiepartners")
    ? "https://server.postzegels.com"
    : "https://server.postzegels.com",
  timeout: 30000,
});

// const API = axios.create({
//   baseURL: window.location.hostname.includes("integratiepartners")
//     ? "http://localhost:3000"
//     : "http://localhost:3000",
//   timeout: 30000,
// });

export default {
  async getSingleKavels(params) {
    try {
      const response = await API.get(`/api/rest/v2/kavel/webshop/nl/Single/data`, {
        params: {
          q: params.q || "",
          page: params.page,
          minPrice: params.minPrice,
          maxPrice: params.maxPrice,
          minKavelNr: params.minKavelNr,
          maxKavelNr: params.maxKavelNr
        }
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getCollectionKavels(params) {
    try {
      const response = await API.get(
        `/api/rest/v2/kavel/webshop/nl/collection/data`,{
        params: {
          q: params.q || "",
          page: params.page,
          minPrice: params.minPrice,
          maxPrice: params.maxPrice,
          minKavelNr: params.minKavelNr,
          maxKavelNr: params.maxKavelNr
        }
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  // async getCollectionKavels() {
  //   try {
  //     const response = await API.get(
  //       "/api/rest/v2/kavel/webshop/nl/collection/data"
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },

  async getSingleKavel(id) {
    try {
      const response = await API.get(`/api/rest/v2/kavel/webshop/NL/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getLastAddedKavel() {
    try {
      const response = await API.get(
        "/api/rest/v2/kavel/webshop/nl/last/nl/data"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getDirectoriesFiles(folderName) {
    try {
      const response = await API.get(
        `/api/rest/v2/kavelfolder/?location=${folderName}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  //Order Kavel
  async OrderKavel(paymentData) {
    try {
      const response = await API.post("/api/rest/v2/order", paymentData);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async shopOrder(paymentData) {
    try {
      const response = await API.post("/api/rest/v2/order/shop/order", paymentData);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async paymentOrder(form) {
    try {
      const response = await API.post("/api/rest/v2/order/payment", form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getLandenMetAantalKavels() {
    try {
      const response = await API.get("/api/rest/v2/land/json");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getSaleKavel() {
    try {
      const response = await API.get("/api/rest/v2/sale");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async validateDiscountCode(code) {
    try {
      const response = await API.post("/api/rest/v2/validatediscountcode",code);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
